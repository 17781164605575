













































































































import Component from 'vue-class-component';
import PatientAddressForm from './forms/PatientAddressForm.vue';
import PatientDetailsForm from './forms/PatientDetailsForm.vue';
import PatientLocationInput from '@/components/inputs/PatientLocationInput.vue';
import Vue from 'vue';
import { Patient } from '@/models';
import { VModel } from 'vue-property-decorator';

@Component({
  name: 'PatientPrimaryDetails',
  components: {
    PatientDetailsForm,
    PatientAddressForm,
    PatientLocationInput,
  },
})
export default class extends Vue {
  @VModel({ type: Patient }) readonly patient!: Patient;

  openPanel = [];

  resetOpenPanel(): void {
    this.openPanel = [];
  }

  async submit(): Promise<void> {
    const updatedPatient = await this.patient.update();
    this.$emit('input', updatedPatient);
    this.resetOpenPanel();
  }
}
