




import { BarChart } from 'vue-chart-3';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import { Metric } from '@/services';
import { PropType, defineComponent, ref } from '@/plugins/composition';
import { colours } from '@/plugins/vuetify';
import { formatDate } from '@/utils';

Chart.register(...registerables);

export default defineComponent({
  name: 'ColumnChart',
  components: { BarChart },
  props: {
    data: {
      required: true,
      type: Object as PropType<Metric>,
    },
  },
  setup(props) {
    const testData = ref<ChartData<'bar'>>({
      datasets: [
        {
          data: props.data.results as unknown as number[],
          label: props.data.question,
          parsing: {
            yAxisKey: 'value',
            xAxisKey: 'date',
          },
          backgroundColor: colours.info,
        },
      ],
    });

    const options = ref<ChartOptions<'bar'>>({
      scales: {
        x: {
          ticks: {
            callback: function (value): string {
              const string = this.getLabelForValue(value as number);
              return formatDate(string) as string;
            },
          },
        },
      },
    });

    return { testData, options };
  },
});
