




















import MetricCard from '../../metrics/components/MetricCard.vue';
import { Metric, getMetrics } from '@/services';
import { Patient } from '@/models';
import { PropType, defineComponent, onMounted, ref } from '@/plugins/composition';

export default defineComponent({
  components: { MetricCard },
  name: 'PatientMetrics',
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const data = ref<Metric[]>([]);
    const loading = ref(true);
    const survey = 'initial-consult-survey';
    const questions = [
      'systolic-blood-pressure',
      'diastolic-blood-pressure',
      'cholesterol',
      'bmi',
      'waist-hip-ratio',
      'weight',
      'vegetables-per-day',
      'fruit-per-day',
      'litres-water-per-day',
      'exercise-per-week',
      'hours-sleep-per-night',
      'waist',
    ];

    onMounted(async () => {
      data.value = await getMetrics(props.patient, survey, questions);
      loading.value = false;
    });

    return {
      data,
      loading,
    };
  },
});
