














import ColumnChart from '../../../metrics/components/ColumnChart.vue';
import MetricCard from '../../../metrics/components/MetricCard.vue';
import PatientMetrics from '../../components/PatientMetrics.vue';
import PatientPrimaryDetails from '../../components/PatientPrimaryDetails.vue';
import UserAddressForm from '../../../../../../components/forms/AddressForm.vue';
import UserContactDetailsForm from '../../components/UserContactDetailsForm.vue';
import { Patient } from '@/models';
import { ProfileTile } from '../../../shared';
import { activeTab } from './activeTab';
import { defineComponent } from 'vue-demi';
import { onMounted, ref } from '@/plugins/composition';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'PatientProfile',
  components: {
    UserAddressForm,
    UserContactDetailsForm,
    ProfileTile,
    PatientPrimaryDetails,
    ColumnChart,
    MetricCard,
    PatientMetrics,
  },
  props: {
    sms: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const drawer = ref(false);
    const patient = ref<Patient | null>(null);

    const { route } = useRouter();

    const getPatient = async (): Promise<void> => {
      const uuid = route.value.params.uuid;
      patient.value = await Patient.find(uuid);
    };

    onMounted(async () => await getPatient());

    return {
      drawer,
      activeTab,
      patient,
    };
  },
});
