







































import ColumnChart from './ColumnChart.vue';
import { Metric } from '@/services';
import { PropType, defineComponent } from '@/plugins/composition';
import { formatDate } from '@/utils/date';

export default defineComponent({
  components: { ColumnChart },
  name: 'MetricCard',
  props: {
    data: {
      required: true,
      type: Object as PropType<Metric>,
    },
  },
  setup() {
    return {
      formatDate,
    };
  },
});
