




































import ClientSelectInput from './ClientSelectInput.vue';
import Component from 'vue-class-component';
import DepartmentSelectInput from './DepartmentSelectInput.vue';
import PositionSelectInput from './PositionSelectInput.vue';
import ShiftSelectInput from './ShiftSelectInput.vue';
import SiteSelectInput from './SiteSelectInput.vue';
import Vue from 'vue';
import { Patient } from '@/models';
import { PatientLocation } from '@/models/PatientLocation';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'PatientLocationInput',
  components: {
    ClientSelectInput,
    SiteSelectInput,
    DepartmentSelectInput,
    PositionSelectInput,
    ShiftSelectInput,
  },
})
export default class extends Vue {
  @Prop({ type: Patient }) readonly patient!: Patient;
  @Prop({ type: Boolean }) readonly allowAdd!: boolean;

  valid = false;

  get location(): PatientLocation | null {
    return this.patient.location;
  }

  cancel(): void {
    this.$emit('cancel');
  }

  async submit(): Promise<void> {
    if (this.location) {
      await this.patient.locations().create(this.location.serialise());
    }
    await this.patient.refresh();
    this.$emit('submit');
    this.$emit('input', this.patient.tempData);
  }
}
