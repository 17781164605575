
















































import Component from 'vue-class-component';
import Vue from 'vue';
import { Address } from '@/models/Address';
import { Prop } from 'vue-property-decorator';
import { User, UserData } from '@/models/User';

@Component
export default class UserContactDetails extends Vue {
  @Prop(User) readonly user!: User;

  valid = false;
  editing = false;

  get userData(): UserData | null {
    if (this.user) {
      return this.user.tempData;
    }
    return null;
  }

  get states(): string[] {
    return Address.states;
  }

  cancel(): void {
    this.editing = false;
  }

  async submit(): Promise<void> {
    if (this.user) {
      await this.user.update();
      this.user.refresh();
    }
    this.editing = false;
  }
}
